<style>
#dashboard-aff{
  min-height: 1000px;
}

#dashboard-aff #console .input-group>div {
  width: 86%;
}

#dashboard-aff .input-group#calendar{
  flex-wrap: nowrap;
}

#dashboard-aff .input-group#calendar .form-control{
  border-radius: 0 0.25rem 0.25rem 0;
}

</style>
<template>
  <div id="dashboard-aff" style="padding: 0 1.5rem;">
    <!-- consol -->
    <div id="console" class="pb-1 mb-0 mb-lg-1">
      <div class="row">
        <div class="col-2 col-lg-3 col-xl-3 d-none d-lg-block text-center  mb-3">
          <button class="btn bg-gradient-secondary btn-block mx-auto" type="button" id="smile2button" @click="start2vdo();">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="#FFFFFF" class="d-inline"><path d="M6.613 18.581m9.387-9.581c0 2.209-1.791 4-4 4s-4-1.791-4-4 1.791-4 4-4 4 1.791 4 4zm-2 0c0-1.103-.896-2-2-2s-2 .897-2 2 .896 2 2 2 2-.897 2-2zm-9 0c0 3.86 3.141 7 7 7s7-3.14 7-7-3.141-7-7-7-7 3.14-7 7zm16 0c0 4.97-4.029 9-9 9s-9-4.03-9-9 4.029-9 9-9 9 4.03 9 9zm-.404 12.501c1.007 1.142-.014 2.679-1.448 2.481-1.795-.245-3.236-1.702-7.147-1.702-3.91 0-5.352 1.458-7.146 1.702-1.436.198-2.456-1.34-1.449-2.481l2.898-3.289c.559.388 1.156.725 1.79.994l-2.025 2.298c1.295-.524 3.065-1.225 5.933-1.225s4.638.7 5.933 1.224l-2.025-2.298c.634-.27 1.231-.606 1.79-.994l2.896 3.29z"/></svg>
            {{$t('checkYourSmile')}} 
          </button>
        </div>
        <DatePicker :pageName="pageName" :start="Global.searchConditions[pageName].dateFrom" :end="Global.searchConditions[pageName].dateTo" :dateRange="Global.searchConditions[pageName].dateRange"></DatePicker>
        <div class="col-12 ms-auto text-center mb-3">
          <div class="card bg-gradient-faded-secondary op50">
            <div class="card-body py-1 px-3">
              <span class="text-sm mb-0 text-info text-center" v-if="syncDateTime">
                <a href="javascript:void(0)" class="text-info" @click="queryDashboard()"><i class="fa fa-refresh text-sm ms-1" aria-hidden="true"></i></a> {{$t("asOf")}} {{ $d(syncDateTime, 'shortWithTime')}}
              </span>
              <span class="spinner-border spinner-border-sm loading text-info" role="status" aria-hidden="true" v-else></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- consol -->
      <div class="row">
        <div class="col-xl-4 col-sm-4 mb-xl-0 mb-4">
          <div class="card" id="GWP-card" >
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-capitalize font-weight-bold">{{$t("totalGWP")}}</p>
                    <span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-show="!GWP"></span>
                    <h5 class="font-weight-bolder mb-0" v-if="GWP != null && !GWP?.isError">
                      <small data-value="thb" v-if="GWP.total">฿</small>{{formatPrice(GWP.total)}}
                      <span class="text-sm font-weight-bolder" :class="percentageClass(GWP.percentage)" v-if="GWP.percentage">{{GWP.percentage}}%</span>
                      
                    </h5>
                    <h5 class="text-sm font-weight-bolder" v-if="GWP?.isError">{{$t("serviceNotAvailable")}}</h5>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-info shadow text-center border-radius-md">
                    <i class="fa fa-briefcase text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-sm-4 mb-xl-0 mb-4">
          <div class="card" id="quotes-card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-capitalize font-weight-bold">{{$t("numberOfQuotes")}}</p>
                    <span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-if="!quotes"></span>
                    <h5 class="font-weight-bolder mb-0" v-else>
                      {{formatPrice(quotes?.total)}}
                    </h5>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-info shadow text-center border-radius-md">
                    <i class="fa fa-group text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-sm-4 mb-xl-0 mb-4">
          <div class="card" id="opportunity-card">
            <div class="card-body p-3">
              <div class="row">
                <div class="col-8">
                  <div class="numbers">
                    <p class="text-sm mb-0 text-capitalize font-weight-bold">{{$t("numberOfOpportunties")}}</p>
                    <span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-if="!opportunity"></span>
                    <h5 class="font-weight-bolder mb-0" v-else>
                      {{formatPrice(opportunity?.total)}}
                      <!-- <a class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto" href="javascript:;" @click="goToReport('created','/opportunity-aff-report')">
                        {{$t("readMore")}}
                        <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                      </a> -->
                    </h5>
                  </div>
                </div>
                <div class="col-4 text-end">
                  <div class="icon icon-shape bg-gradient-info shadow text-center border-radius-md">
                    <i class="fa fa-group text-lg opacity-10" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12 col-md-8 col-lg-5 mb-lg-0 mb-4">
          <div class="card h-100" id="commission-card">
              <div class="card-body p-3">
                  <div class="row">
                      <div class="col-8 col-sm-9 col-lg-7">
                          <div class="d-flex flex-column h-100">
                              <p class="mb-1 pt-2 text-normal">{{getDisplayMonthAndYearFromDateRange2()}}</p>
                              <h5 class="font-weight-bolder">{{$t("commission.topic")}}</h5>
                              <span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-if="!commission"></span>
                              <template v-if="commission">                         
                                <h2 class="font-weight-bolder mt-0">
                                <small data-value="thb" v-if="commission.total">฿</small>{{formatPrice(commission.total)}}
                                <span class="text-sm font-weight-bolder" :class="percentageClass(commission.percentage)" v-if="commission.percentage">{{commission.percentage}}%</span>
                                </h2>
                              </template>
                          </div>
                      </div>
                      <div class="col-4 col-sm-3 col-lg-5 ms-auto text-center mt-5 mt-lg-0">
                          <div class="bg-gradient-info border-radius-lg pt-3 pb-5">
                              <div class="position-relative d-flex align-items-center justify-content-center h-100">
                              <img class="w-100 position-relative z-index-2 pt-4" :src="publicPath + 'img/salary2x.png'" alt="money">
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="col-12 col-md-4 col-lg-3 mb-lg-0 mb-4">
          <div class="card h-100 p-0 bg2sold" id="policy-sold-card">
              <div class="card-boday p-3 ">
                  <div class="d-flex flex-column h-100" style="min-height: 200px;">
                      <p class="mb-1 pt-2 text-normal">{{getDisplayMonthAndYearFromDateRange2()}}</p>
                      <h5 class="font-weight-bolder">{{$t("policySold.topic")}}</h5>

                      <span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-if="!policySold"></span>
                      <template v-if="policySold">         
                        <h2 class="font-weight-bolder mt-0">{{policySold.total}}
                            <span class="text-success text-sm font-weight-bolder" :class="percentageClass(policySold.percentage)" v-if="policySold.percentage">{{policySold.percentage}}%</span>
                        </h2>
                      </template>
                      <p class="mb-1" v-if="policySold?.isError">{{$t("serviceNotAvailable")}}</p>
                  </div>
                  <!-- <a class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto d-block" href="javascript:;" @click="goToReport('sold','/opportunity-aff-report')">
                      {{$t("readMore")}}
                      <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                  </a> -->
              </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="card h-100" id="cancellation-card">
              <div class="d-flex flex-column h-100">
                  <div class="card-header pb-0 ">
                      <h6>{{$t("cancellation.topic")}} <span class="badge bg-gradient-danger" v-if="false">{{cancellation.total}}</span></h6>
                  </div>
                  <div class="card-body p-3 d-flex flex-column">
                      <span class="spinner-border spinner-border-lg loading text-info m-3" role="status" aria-hidden="true" v-if="!cancellation"></span>
                      <template v-if="cancellation">
                        <div class="timeline timeline-one-side d-none">
                            <div class="timeline-block mb-3">
                                <span class="timeline-step">
                                    <i class="fa fa-bell text-danger text-gradient"></i>
                                </span>
                                <div class="timeline-content" v-for="(cancel, index) in cancellation.policies" v-bind:key="index">
                                    <h6 class="text-dark text-sm font-weight-bold mb-0">{{$t("carPolicy")}} {{cancel.opportunityNumber}}</h6>
                                    <p class="text-secondary font-weight-bold text-xs mt-1 mb-0">{{cancel.created}}</p>
                                </div>
                            </div>
                        </div>
                        <ul class="list-group list-group-flush" data-toggle="checklist">
                          <li class="list-group-item border-0 flex-column align-items-start ps-0 py-0 mb-0">
                            <div class="checklist-item checklist-item-danger ps-2 ms-3">
                              <div class="d-flex align-items-center">
                                <img :src="publicPath + 'img/cancel2alert.png'" alt="Cancelation!" class="max-w100" />
                                <div class="super2state danger position-absolute start-50">
                                  <div class="text-white mb-0">{{cancellation.total}}</div>
                                  <div class="arrow2danger"></div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <!-- <a class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto d-block" href="javascript:;" @click="goToReport('cancellation','/cancellation-aff-report')">
                            {{$t("readMore")}}
                            <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                        </a> -->
                      </template>
                      <p class="mb-1" v-if="cancellation?.isError">{{$t("serviceNotAvailable")}}</p>
                  </div>
                  <div class="card-body p-3 empty2state mx-auto d-none">
                      <img :src="publicPath + 'img/empty2state.png'" class="max-w100" alt="Not engough information!">
                  </div>
              </div>
          </div>
        </div>
      </div>
      
    </div>
</template>

<script>
import axios from "axios";
import Global from "@/stores/GlobalVariables.js";
import mixin from "@/components/Mixin";
import DatePicker from "@/components/DatePicker";
import dayjs from "@/dayjs.js";

export default {
  mixins: [mixin],
  name: "DashboardAffiliate",
  components: {
    DatePicker,
  },
  data: function () {
    return {
      pageName : "dashboardAffiliate",
      syncDateTime: Global.dashboardAffiliate.syncDateTime,
      quotes: Global.dashboardAffiliate.quotes,
      opportunity: Global.dashboardAffiliate.opportunity,
      newPolicy: Global.dashboardAffiliate.newPolicy,
      policySold: Global.dashboardAffiliate.policySold,
      cancellation: Global.dashboardAffiliate.cancellation,
      GWP: Global.dashboardAffiliate.GWP,
      commission: Global.dashboardAffiliate.commission,
    };
  },
  created(){
    var vm = this;
    vm.$watch(
      () => (Global.searchConditions.dashboardAffiliate.dateFrom, Global.searchConditions.dashboardAffiliate.dateTo, Date.now()),
      function () {
        setTimeout(function(){
          if(!vm.isNullOrEmpty(Global.searchConditions.dashboardAffiliate.dateFrom) && !vm.isNullOrEmpty(Global.searchConditions.dashboardAffiliate.dateTo)){
            vm.queryDashboard();
          }
        }, 100);
      }
    )
    this.eventTracking('mainMenu', 'Menu_Dashboard', 'Portal');
  },
  methods: {
    queryDashboard: async function(){
      var vm = this;
      vm.getQuotes();
      vm.getOpportunities();
      vm.getPolicySoldReport(); 
      vm.getCancellationReport();  
      vm.getTotalGWP();
      vm.getTotalCommission(); 
      vm.syncDateTime = await vm.updateSyncDateTime();
      Global.dashboardAffiliate.syncDateTime = vm.syncDateTime;
    },
		getQuotes: async function(){
      var vm = this;
      vm.quotes = null;
      await axios.get(Global.apiUrl + "/dashboard/affiliateQuote", {params: Global.searchConditions.dashboardAffiliate})
      .then(function(response) {
        vm.quotes = response.data;
        Global.dashboardAffiliate.quotes = vm.quotes;
      })
      .catch(function(error) {
        console.log(error);
      });
      console.log('getQuotes')
    },
    getOpportunities: async function(){
      var vm = this;
      vm.opportunity = null;
      await axios.get(Global.apiUrl + "/dashboard/affiliateOpp", {params: Global.searchConditions.dashboardAffiliate})
      .then(function(response) {
        vm.opportunity = response.data;
        Global.dashboardAffiliate.opportunity = vm.opportunity;
      })
      .catch(function(error) {
        console.log(error);
      });
    },
    getPolicySoldReport: async function(){
      var vm = this;
      vm.policySold = null;
      await axios.get(Global.apiUrl + "/dashboard/affiliatePolicySold", {params: Global.searchConditions.dashboardAffiliate})
      .then(function(response) {
        vm.policySold = response.data;
        Global.dashboardAffiliate.policySold = vm.policySold;
      })
      .catch(function(error) {
        console.log(error);
      });
		},
    getCancellationReport: async function(){
      var vm = this;
      vm.cancellation = null;
      await axios.get(Global.apiUrl + "/dashboard/cancellation", {params: Global.searchConditions.dashboardAffiliate})
      .then(function(response) {
        vm.cancellation = response.data;
        Global.dashboardAffiliate.cancellation = vm.cancellation;
      })
      .catch(function(error) {
        console.log(error);
      });
		},
    getTotalGWP: async function(){
      var vm = this;
      vm.GWP = null;
      await axios.get(Global.apiUrl + "/dashboard/affiliateGWP",{params: Global.searchConditions.dashboardAffiliate})
      .then(function(response){
        vm.GWP = response.data.totalGWP;
        Global.dashboardAffiliate.GWP = vm.GWP;
      })
      .catch(function(error) {
        console.log(error);
      });
    },
    getTotalCommission: async function(){
      var vm = this;
      vm.commission = null;
      await axios.get(Global.apiUrl + "/dashboard/affiliateCommission",{params: Global.searchConditions.dashboardAffiliate})
      .then(function(response){
        vm.commission = response.data.commission;
        Global.dashboardAffiliate.commission = vm.commission;
      })
      .catch(function(error) {
        console.log(error);
      });
    },
    isDisplayInfo: function(data){
      if(data && data.total > 0) return true;
      else return false;
    },
    
    getDisplayMonthAndYearFromDateRange: function(){
      var startMonth = dayjs(Global.searchConditions.dashboardAffiliate.dateFrom).format('MMMM'),
          startYear = dayjs(Global.searchConditions.dashboardAffiliate.dateFrom).format('YYYY'),
          endMonth = dayjs(Global.searchConditions.dashboardAffiliate.dateTo).format('MMMM'),
          endYear = dayjs(Global.searchConditions.dashboardAffiliate.dateTo).format('YYYY');
      if(startMonth === endMonth && startYear === endYear){
          return startMonth + ' ' + startYear;
      }else if(startMonth !== endMonth && startYear === endYear){
          return startMonth + ' - ' + endMonth + ' ' + startYear;
      }else{
        return startMonth + ' ' + startYear + ' - ' + endMonth + ' ' + endYear;
      }
    },
    getDisplayMonthAndYearFromDateRange2: function(){
      if(!this.isNullOrEmpty(Global.searchConditions.dashboardAffiliate.dateFrom) && !this.isNullOrEmpty(Global.searchConditions.dashboardAffiliate.dateTo)){
        var startMonth = new Date(Global.searchConditions.dashboardAffiliate.dateFrom),
            startYear = new Date(Global.searchConditions.dashboardAffiliate.dateFrom),
            endMonth = new Date(Global.searchConditions.dashboardAffiliate.dateTo),
            endYear = new Date(Global.searchConditions.dashboardAffiliate.dateTo);
        if(startMonth.getMonth() === endMonth.getMonth() && startYear.getFullYear() === endYear.getFullYear()){
            return this.$d(startMonth, 'month') + ' ' + this.$d(startYear, 'year');
        }else if(startMonth.getMonth() !== endMonth.getMonth() && startYear.getFullYear() === endYear.getFullYear()){
            return this.$d(startMonth, 'shortMonth') + ' - ' + this.$d(endMonth, 'shortMonth') + ' ' + this.$d(startYear, 'year');
        }else{
          return this.$d(startMonth, 'month') + ' ' + this.$d(startYear, 'year') + ' - ' + this.$d(endMonth, 'month') + ' ' + this.$d(endYear, 'year');
        }
      }
    },
    goToReport: function(fromReport, path){
      var vm = this;
      var params = {start: null,
                    end: null};
      if(fromReport === 'cancellation'){
        params.start = Global.searchConditions.dashboardAffiliate.dateFrom;
        params.end = Global.searchConditions.dashboardAffiliate.dateTo;
        params.stage = 'Closed Won';
        params.status = 'Cancelled';
        params.dateType = 'cancelleddate';
      }else if(fromReport === 'created'){
        params.start = Global.searchConditions.dashboardAffiliate.dateFrom;
        params.end = Global.searchConditions.dashboardAffiliate.dateTo;
        params.dateType = 'createddate';
      }else if(fromReport === 'sold'){
        params.start = Global.searchConditions.dashboardAffiliate.dateFrom;
        params.end = Global.searchConditions.dashboardAffiliate.dateTo;
        params.dateType = 'activatedate';
        params.status = 'Activated'
      }
      vm.$router.push({ path: path, query: params});
    },
    i18nLabel: function(labels){
      var i18nLabel = [];
      labels.forEach(label => {
        i18nLabel.push(this.$t(label))
      });
      return i18nLabel;
    },  
  },
  watch: {
    "Global.locale": async function(){
      var vm = this;
      setTimeout(function(){
        vm.createPieChart("new-policy", vm.newPolicy.labels, vm.newPolicy.datas);
        vm.createDoughnutChart("renew", vm.renew.labels, vm.renew.datas);
      }, 500);
    }
  },
};
</script>
<i18n>
{
  "en": {
    "checkYourSmile": "Check your smile",
    "asOf": "AS of",
    "totalGWP": "Total collected premium",
    "numberOfQuotes": "Number of quotes",
    "numberOfOpportunties": "Number of opportunties",
    "summary.outstandingOpportunity": "Outstanding Opportunity (last 90 days)",
    "summary.paymentFailure": "Payment Failure",
    "needHelp": "Need help",
    "commission.topic": "Estimate Commission",
    "commission.message": "Fantastic! you are in the top 10 of most sales.",
    "policySold.topic": "Policy Sold",
    "policySold.newPolicy": "New policy",
    "policySold.renew": "Renew",
    "policySold.endorsement": "Endorsement",
    "cancellation.topic": "Cancellation",
    "cancellation.carPolicy": "Car policy",
    "history": "History",
    "readMore": "Read more",
    "carPolicy": "Car policy",
    "than": "than",
    "lastMonth": "last month",
    "newPolicy.topic": "New policy",
    "pendingInspection.topic": "Pending inspection",
    "renew.topic": "Renew",
    "Car": "Car",
    "Motorbike": "Motorbike", 
    "EW": "EW", 
    "CANCER" : "CANCER", 
    "PA": "PA",
    "getNewQuotation": "Get new quotation",
    "next": "Next"
  },
  "th": {
    "checkYourSmile": "วันนี้คุณยิ้มแล้วหรือยัง",
    "asOf": "ข้อมูลล่าสุด ณ วันที่",
    "totalGWP": "เบี้ยประกันภัยรวม",
    "numberOfQuotes": "จำนวนที่เช็คราคา",
    "numberOfOpportunties": "จำนวนใบเสนอราคาที่สร้าง",
    "summary.paymentFailure": "การชำระเงินไม่สำเร็จ",
    "needHelp": "ต้องการความช่วยเหลือ",
    "commission.topic": "ประมาณการค่าการตลาด",
    "commission.message": "Fantastic! you are in the top 10 of most sales.",
    "policySold.topic": "กรมธรรม์ที่ขายได้",
    "policySold.newPolicy": "กรมธรรม์ใหม่",
    "policySold.renew": "กรมธรรม์ต่ออายุ",
    "policySold.endorsement": "รายการสลักหลัง",
    "cancellation.topic": "กรมธรรม์ที่ถูกยกเลิก",
    "cancellationcarPolicy": "Car policy",
    "history": "History",
    "readMore": "ข้อมูลเพิ่มเติม",
    "carPolicy": "Car policy",
    "than": "than",
    "lastMonth": "last month",
    "newPolicy.topic": "กรมธรรม์ใหม่",
    "pendingInspection.topic": "กรมธรรม์รอตรวจสภาพ",
    "renew.topic": "กรมธรรม์ต่ออายุ",
    "Car": "รถยนต์",
    "Motorbike": "มอเตอร์ไซค์", 
    "EW": "อะไหล่", 
    "CANCER" : "มะเร็ง", 
    "PA": "อุบัติเหตุส่วนบุคคล",
    "getNewQuotation": "ใบเสนอราคาใหม่",
    "next": "ถัดไป"
  },
  "zh" : {
    "checkYourSmile": "Check your smile",
    "asOf": "AS of",
    "dataRange": "数据范围",
    "totalGWP": "Total collected premium",
    "numberOfQuotes": "Number of quotes",
    "numberOfOpportunties": "Number of opportunties",
    "summary.paymentFailure": "Payment Failure",
    "needHelp": "Need help",
    "commission.topic": "Estimate Commission",
    "commission.message": "Fantastic! you are in the top 10 of most sales.",
    "policySold.topic": "Policy Sold",
    "policySold.newPolicy": "New policy",
    "policySold.renew": "Renew",
    "policySold.endorsement": "Endorsement",
    "cancellation.topic": "Cancellation",
    "cancellation.carPolicy": "Car policy",
    "history": "History",
    "readMore": "Read more",
    "carPolicy": "Car policy",
    "than": "than",
    "lastMonth": "last month",
    "newPolicy.topic": "New policy",
    "pendingInspection.topic": "等待总数",
    "renew.topic": "Renew",
    "Car": "Car",
    "Motorbike": "Motorbike", 
    "EW": "EW", 
    "CANCER" : "CANCER", 
    "PA": "PA",
    "getNewQuotation": "Get new quotation",
    "next": "Next"
  }
}
</i18n>
