export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "checkYourSmile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your smile"])},
        "asOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS of"])},
        "totalGWP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total collected premium"])},
        "numberOfQuotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of quotes"])},
        "numberOfOpportunties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of opportunties"])},
        "summary.outstandingOpportunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Outstanding Opportunity (last 90 days)"])},
        "summary.paymentFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Failure"])},
        "needHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help"])},
        "commission.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate Commission"])},
        "commission.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fantastic! you are in the top 10 of most sales."])},
        "policySold.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy Sold"])},
        "policySold.newPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New policy"])},
        "policySold.renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew"])},
        "policySold.endorsement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endorsement"])},
        "cancellation.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation"])},
        "cancellation.carPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car policy"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
        "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
        "carPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car policy"])},
        "than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["than"])},
        "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last month"])},
        "newPolicy.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New policy"])},
        "pendingInspection.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending inspection"])},
        "renew.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew"])},
        "Car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car"])},
        "Motorbike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motorbike"])},
        "EW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EW"])},
        "CANCER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCER"])},
        "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PA"])},
        "getNewQuotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get new quotation"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
      },
      "th": {
        "checkYourSmile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันนี้คุณยิ้มแล้วหรือยัง"])},
        "asOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลล่าสุด ณ วันที่"])},
        "totalGWP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบี้ยประกันภัยรวม"])},
        "numberOfQuotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนที่เช็คราคา"])},
        "numberOfOpportunties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนใบเสนอราคาที่สร้าง"])},
        "summary.paymentFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การชำระเงินไม่สำเร็จ"])},
        "needHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ต้องการความช่วยเหลือ"])},
        "commission.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประมาณการค่าการตลาด"])},
        "commission.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fantastic! you are in the top 10 of most sales."])},
        "policySold.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรมธรรม์ที่ขายได้"])},
        "policySold.newPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรมธรรม์ใหม่"])},
        "policySold.renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรมธรรม์ต่ออายุ"])},
        "policySold.endorsement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการสลักหลัง"])},
        "cancellation.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรมธรรม์ที่ถูกยกเลิก"])},
        "cancellationcarPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car policy"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
        "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลเพิ่มเติม"])},
        "carPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car policy"])},
        "than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["than"])},
        "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last month"])},
        "newPolicy.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรมธรรม์ใหม่"])},
        "pendingInspection.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรมธรรม์รอตรวจสภาพ"])},
        "renew.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรมธรรม์ต่ออายุ"])},
        "Car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รถยนต์"])},
        "Motorbike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มอเตอร์ไซค์"])},
        "EW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อะไหล่"])},
        "CANCER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มะเร็ง"])},
        "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อุบัติเหตุส่วนบุคคล"])},
        "getNewQuotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใบเสนอราคาใหม่"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถัดไป"])}
      },
      "zh": {
        "checkYourSmile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your smile"])},
        "asOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS of"])},
        "dataRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数据范围"])},
        "totalGWP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total collected premium"])},
        "numberOfQuotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of quotes"])},
        "numberOfOpportunties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of opportunties"])},
        "summary.paymentFailure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Failure"])},
        "needHelp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help"])},
        "commission.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate Commission"])},
        "commission.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fantastic! you are in the top 10 of most sales."])},
        "policySold.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policy Sold"])},
        "policySold.newPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New policy"])},
        "policySold.renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew"])},
        "policySold.endorsement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endorsement"])},
        "cancellation.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancellation"])},
        "cancellation.carPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car policy"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
        "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
        "carPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car policy"])},
        "than": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["than"])},
        "lastMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last month"])},
        "newPolicy.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New policy"])},
        "pendingInspection.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待总数"])},
        "renew.topic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renew"])},
        "Car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car"])},
        "Motorbike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motorbike"])},
        "EW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EW"])},
        "CANCER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCER"])},
        "PA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PA"])},
        "getNewQuotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get new quotation"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
      }
    }
  })
}
